.business-card {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    /* align-items: flex-start; Align items to the start of the container */
    padding: 10px;
    /* background-color: #f5f5f5; */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .name {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px; /* Add some space between the name and the titles */
    margin-top: 0px;
    justify-content: center ;
  }
  
  .titles {
    display: flex;
    gap: 3px;  /* Space between titles */
    justify-content: center;
  }
  
  .title {
    background-color: #606060  ;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.9em;
  }