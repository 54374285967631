button {
    border: none;
    color: white;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  button i {
    margin-right: 8px; /* Space between icon and text */
  }
  
  .email-button {
    background-color: #333;
    margin:5px;
  }
  
  .email-button:hover {
    background-color: #555;
  }
  
  .linkedin-button {
    background-color: #0077b5;
    margin:5px;
  }
  
  .linkedin-button:hover {
    background-color: #005691;
  }

  .contact{
    margin: 10px;
    display: flex;
    justify-content: center;
  }

  .contact span {
    margin-left: 5px;
  }