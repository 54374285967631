
.background {
  position: relative; /* Needed to position the pseudo-element */
  height: 100vh;
  width: 100vw;
}

.background::before {
  content: ""; /* Required for pseudo-elements */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./assets/neural-network-background.jpg"); /* Adjust path as needed */
  background-size: cover;
  background-position: center;
  opacity: 0.05; /* Adjust translucency here, lower is more translucent */
  z-index: -1; /* Ensures the pseudo-element is behind the content */
}

.App {
  text-align: center;
} 




