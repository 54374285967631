* {
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
  }

  
  
  nav {
    background: #6398c0;
    height: 80px;
    width: 100%;
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; /* Added to ensure child elements are positioned correctly */
  }
  
  nav .logo {
    color: white;
    font-size: 35px;
    line-height: 80px;
    font-weight: bold;
  }
  
  nav ul {
    display: flex;
    margin-right: 20px;
    transition: all 0.5s; /* Added transition for smoother appearance */
  }
  
  nav ul li {
    display: inline-block;
    line-height: 80px;
    margin: 0 5px;
  }
  
  nav ul li a, nav ul li Link  {
    color: white;
    font-size: 17px;
    padding: 7px 13px;
    border-radius: 3px;
    text-transform: uppercase;
  }
  
  a.active,
  a:hover {
    background: #4185ba;
    transition: 0.5s;
  }
  
  .checkbtn {
    font-size: 30px;
    color: white;
    display: none; /* Hidden by default and shown on smaller screens */
    cursor: pointer;
  }
  
  #check {
    display: none;
  }
  
  @media (max-width: 800px) {
    label.logo {
      font-size: 30px;
    }
    nav ul li a, nav ul li Link {
      font-size: 16px;
    }
  }
  
  @media (max-width: 700px) {
    .checkbtn {
      display: block; /* Show toggle button */
      position: absolute;
      right: 20px;
      top: 25px;
    }
    nav ul {
      position: fixed;
      width: 100%;
      height: 100vh;
      background: #2c3e50;
      top: 80px;
      left: -100%; /* Initially hide the menu off-screen */
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
    nav ul li {
      margin: 20px 0; /* Reduced margin for better spacing */
      line-height: 30px;
    }
    nav ul li a, nav ul li Link {
      font-size: 20px;
    }
    a:hover,Link.hover,  
    a.active,Link.active  {
      background: none;
      color: #4185ba;
    }
    #check:checked ~ ul {
      left: 0; /* Move menu into view when checkbox is checked */
    }
  }

  